<script>
import appConfig from "@/app.config";
export default {
    data() {
    return {
      pageTitle: appConfig.pageTitle,
    };
  },
}
</script>

<template>
    <footer class="footer">
    <div class="container-fluid">
        <div class="row">
            
            <div>© {{ new Date().getFullYear()}} {{pageTitle}}. All Rights Reserved. Privacy Policy</div>
        </div>
    </div>
</footer>
</template>